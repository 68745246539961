

.container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  width: 720px;
  margin: 20px;
  box-shadow: 0 4px 15px 5px #0000001a;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;


  border-radius: 25px;
}
body{
  color: #fff;
  background-color: #000053e6;
  background-image: url('../../../static/img/bg-1.jpg');
  background-position: center;
  background-size: cover;
  height: 100vh;

}
#output-container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  min-height: 60vh;

}

.messages-container {
  /* width: 70%; */

  width: 55%;

}

.message-item {
  display: grid;
  grid-template-rows: auto auto;
  /* Two rows */
  grid-template-columns: 1fr 1fr;
  /* Two columns, both full width */

  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  /* Optional: Add border for clarity */
}

.success-status {
  grid-row: 1;
  /* First row */
  grid-column: 1;
  /* First column */
  font-size: 14px;
  color: green;
  /* Customize color as needed */
  /* background-color: #f1f1f1; */
  border-radius: 5px;

  background-color: var(--intercom-operator-message-bg);
  padding: 15px;
  margin: 10px 0px;
  width: fit-content;

  font-family: sans-serif;
}

.message p{
line-height: 1.8 !important;
}
.message {
  grid-row: 2;
  /* Second row */
  grid-column: 2;
  /* Second column */
  font-size: 15px;
  color: #333;
  /* Customize color as needed */
  /* Align text to the right */
  background-color: #f1f1f1;
  border-radius: 5px;

  background-color: #c5c5ff;
  /* width: fit-content; */
  margin-left: 40px;
  padding: 11px;
  /* color: white; */
  margin-right: 5px;
  width: 65%;
  font-family: sans-serif;
}

.hello-container img {
  height: 300px;
}

/* .hello-container video{
height: 50vh;
} */



.hello-container {
  width: 45%;
  text-align: right;
  font-size: 18px;
  font-weight: bold;

  color: #007bff;

  display: flex;
  justify-content: center;
  align-items: center;

  /* height: fit-content; */
  height: 60vh;
  /* padding: 50px; */
}

.input-container {
  text-align: center;
  width: 100%;
  padding: 18px;
}

input[type="text"] {
  /* flex: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px; */
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  border: none;
  border-radius: 0 5px 5px 0;
  color: white;
  cursor: pointer;
}

button i {
  font-size: 16px;
}



.positionrelative video{
  height: 370px;
  border-radius: 25px !important;
}


.positionrelative.version3 video{
  height: 450px;
}









/* recording send in api start */

/* Ensure these styles are added or updated in frameNew.css */
/* Ensure these styles are added or updated in frameNew.css */
.message-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 100%;
  border: none;
  /* Ensure the message item does not exceed the container width */
}

.message {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  /* Wrap long words to the next line */
  overflow-wrap: break-word;
  /* Ensure long words break and wrap within the container */
  max-width: 100%;
  /* Ensure the message container does not exceed its parent width */
}

audio {
  margin-top: 5px;
  width: 100%;
}

/* Optional: Style the message container */
.messages-container {
  max-width: 600px;
  /* Adjust width as needed */
  overflow: auto;
  max-height: 60vh;
  /* Add scroll if needed */
}

.input-container input {
  border: none;
  outline: none;
}

.input-container input {
  border: none;
  outline: none;
}

.input-container textarea {
  border: none;
  outline: none;
  width: 90%;
  font-family: sans-serif;
  height: 200px;
}

.input-container button {
  margin-left: 10px;
}

.recording-indicator {
  display: flex;
  align-items: center;
  position: relative;
  /* margin-right: 10px; */
}

.recording-line {
  width: 100%;
  height: 4px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.timer {
  margin-left: 8px;
  font-weight: bold;
  margin-bottom: 15px;
}

.recording-indicator button {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  margin-left: 10px;
}

.recording-indicator button i {
  margin-right: 5px;
}




/* .message audio {
  align-self: flex-end;
  width: 50%;
} */



/* css as per figma start */

.chatHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7ead18;
  padding: 18px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  color: white;
}

.iconHeadChat img,
.headMinClose img {
  margin-right: 12px;
}

.iconHeadChat {
  display: flex;
  align-items: center;
}

.headTextClass .miniHead {
  font-size: 8px;
  margin-bottom: 6px;
}

.headTextClass {
  font-family: Arial, Helvetica, sans-serif;
}

.iconButton,
.headMinClose img {
  cursor: pointer;
  margin-right: 10px;
  height: 22px;
}

.iconButton img {
  height: 20px;
}

.receiverIconText,
.senderIconText {
  display: flex;
  align-items: center;
}

.senderIconText {
  justify-content: flex-end;
}


.helpfulText img {
  cursor: pointer;
}

.helpfulText .thumbsDownClass {
  margin-top: 7px;
}

.helpfulText {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}





.hoverZoom {

  transition: transform .2s;
  margin-left: 5px;
}

.hoverZoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}





/* css as per figma end */



.staticVideoClass {
  height: 0vh;
}



.container {
  transition: transform 1s ease, opacity 1s ease;
}

.container.slide-down {
  transform: translateY(calc(100vh - 60px));
  opacity: 1;
}

.chatHead {
  transition: transform 1s ease;
  bottom: 0;
  width: 100%;
}

.container.slide-down .chatHead {
  transform: translateY(0);
}






/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Internet Explorer 10+ */
* {
  -ms-overflow-style: none; /* Internet Explorer and Edge (legacy) */
}






/* dharmesh css */

/* .container{
  position: fixed;
    right: 0;
} */


.videochartvalue {
  /* border: 1px solid #9ec5e5; */
  border-radius: 11px 11px 0px 0px;
  bottom: -59px;

  /* height: 355px; */
  /* transition: height 3s; */

  display: flex;
  justify-content: flex-end;
  /* height: fit-content; */
  /* transition: height 0.5s ease, padding 0.5s ease;  */

  transition: height 0.5s ease, width 0.5s ease; 
}

.newvideochartvalue {
  border-radius: 11px 11px 0px 0px;
  bottom: 0;
  height: 0;
  /* width: 400px; */
  position: fixed;
  bottom: 100px;
  right: 15px;
  transition: height 3s;
}




/* css for api response in chat start  */


.receiver .senderIconText .message {
  margin: 0;
  background-color: #f2f2f2;
  line-height: 1.6 !important;
}

.receiver .senderIconText {
  flex-direction: row-reverse;
}

/* css for api response in chat end */




.chatBoxImageClass img {
  cursor: pointer;
  bottom: 25px;
width: fit-content;
  right: -160px;
  /* left: 950px; */
  height: 220px;
  position: absolute;
  /* box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); */
  border-radius: 3px;
  animation: Floating 3s ease-in-out infinite;
}

@keyframes Floating {
0% {
  transform: translate(0, 0);
}
65% {
  transform: translate(0, 50px);
}
100% {
  transform: translate(0, 0);
}
}







/* CSS for mobile view */

/* 
@media (width: 768px) {
  .hello-container {
      height: 28vh;
      padding: 90px;
  }
} */

.whitecontainer{
  
  background-color: rgb(0, 0, 83, 0.9) !important;
  
  border-radius: 50px !important;
    padding: 40px 40px !important;
   
  max-width: 900px !important;
  margin: 0 auto !important;
}









.userformHeader2{
  color: #7ead18 !important;
  font-size: 12px !important;
}
.Backgroundcolor2{
  background: linear-gradient(180deg, #76b852 0%,  #DCE35B 100%);
  display: inline-block;
    width: 80px;
    height: 80px;

    border-radius: 100px;
  
     

     
}

.Backgroundcolor3{
  background: linear-gradient(180deg, #76b852 0%,  #DCE35B 100%);
  display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 35px;
    border-radius: 100px;
    position: absolute;
    bottom: -38px;
    left: 41%;
     
}

.backgroundcolorimage{
  background: linear-gradient(#76b852 0%, #dce35b 100%);
    width: 80px;
    height: 80px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    margin: 0 auto;
    line-height: 80px;
    position: relative;
    left: auto;
}
.Backgroundcolor3 img{
  margin-top: 10px;
}
.fromfillinginput{
  background-color: rgb(70, 54, 154, 0.5);
    width: 100%;
    height: 35px;
    padding: 10px 17px;
    border-radius: 20px;
    box-shadow: none;
    border: none;
  color: #fff;
  margin-right: 16px;
  margin-top: 8px;
}
.fromfillinginput:focus{
  outline: none;
}
.fromfillingbutton{
  width: 100%;
  height: 35px;
  background-color: #7ead18 !important;
  border-radius: 20px !important; 
  margin-top: 10px !important;
}
.classspositioncent{
  padding-left: 100px;
  text-align: left;
  cursor: pointer;
  font-size: 22px;
}

.classspositioncent2{
  position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    /* bottom: -75px; */
    cursor: pointer;
 
}
.classspositioncent2 img{
  
}
.closebuttonkeyboard {
  position: absolute;
  top: -15px;
  right: -8px;
  background: red;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}
.classspositioncent2 .fa {
  text-align: center;
  background: #7ead18;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  font-size: 51px;
  line-height: 80px;
  
}
.positionrelative{
  position: relative;
}
 
.speaknowbutton{
  padding: 10px;
  font-family: Roboto, Helvetica, Arial, sans-serif
}

@media only screen and (min-width: 1279px)   {
  .whitecontainer{
    border-radius: 10px;
   
  }
  /* .logopss{
    padding: 10px !important;
  } */
}

@media only screen and (min-width: 1366px)   {
  /* .whitecontainer{
    margin-top: 70px !important;
  } */
  /* .logopss{
    padding: 30px !important;
  } */
}

@media (max-width: 400px) {
  .container {
    min-height: 73vh !important; 
  }
}



/* css for chat loading start */


.chat-bubble {
  /*   background-color:#E6F8F1; */
    padding:16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    display:inline-block;
  }
  .typing {
    align-items: center;
    display: flex;
    height: 17px;
  }
  .typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #6CAD96 ; 
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }
  .typing .dot:nth-child(1) {
    animation-delay: 200ms;
  }
  .typing .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  .typing .dot:nth-child(3) {
    animation-delay: 400ms;
  }
  .typing .dot:last-child {
    margin-right: 0;
  }
  
  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color:#6CAD96; 
    }
    28% {
      transform: translateY(-7px);
      background-color:#9ECAB9; 
    }
    44% {
      transform: translateY(0px);
      background-color: #B5D9CB; 
    }
  }



/* css for chat loading end */











.chat-bubble-audio{
  position: relative;
  padding: 5px;
  border-radius: 10px;
  max-width: 300px;
  /* margin: 10px 0; */
}


.chat-bubble {
  position: relative;
  padding: 5px;
  background-color: #f1f0f0;
  border-radius: 10px;
  max-width: 300px;
  /* margin: 10px 0; */
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: gray;
  border-radius: 50%;
  animation: typing 1s infinite ease-in-out;
}

@keyframes typing {
  0%, 100% {
    opacity: 0.2;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
}



/* css for loader before video start  */

.loaderVideo {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #7ead18;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* css for loader before video end  */


.loaderVideoOutside{
  height: 50vh;
    background-color: content-box;
    /* background-color: antiquewhite; */
    display: flex;
    align-items: center;
}



.container{
  min-height: 80vh;
  background-color: #fff;
  position: absolute;
  top: 40px;
}

.videocon{
  max-width: 100%;
  object-fit: cover;
}
.hellovideochartvalue{
  width: 300px;
}
/* ********************************************** */

.videochartvalue{
  bottom: -25px;
}


.slideChatBox {
  position: relative;
  opacity: 0;
  animation: slide-up 2s ease-in-out forwards;
}
/* .backgroundColor{
  background-image: url('../../../static/img/bg-1.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  height: 100vh;
  background-color: rgb(0, 0, 83, 0.9)
} */
.logopss{
  padding: 0px;
  text-align: center;
}
.userformHeader{
  padding-bottom: 20px;
  font-size: 30px !important;
  font-weight: bold !important;
}
.datepickerlocal input{
  padding: 10px;
    font-size: 12px;
}
.datepickerlocal label{
  line-height: 13px;
}

/* Keyframes for the sliding up animation */
@keyframes slide-up {
  0% {
    transform: translateY(800px); /* Start from 100px below */
    opacity: 1;
  }


  100% {
    transform: translateY(100px); /* End at the original position */
    opacity: 1;
  }
} 
.keyboardclass{
  width: 900px;
margin: auto;
margin-top: 80px;
position: relative;
}
.exportButon {
  text-align: right;
  color: #fff;
  font-size: 18px;
  margin-bottom: 20px;
}
.exportButon a{
  text-align: right;
  color: #fff;
  font-size: 18px;
}
 
.exportbuttonnew {
  background: rgb(70, 54, 154);
    padding: 10px 60px;
    border-radius: 30px;
}
.exportbuttonnew:hover{
  background: #76b852;
  color: #fff;

}
.MuiTableHead-root tr th{
  font-weight: bold;
}
.colorwhite{
  color: #fff;
  text-align: end;
  padding-right: 15px;
  padding: 15px 15px 0px 0px;
}
.colorwhite a {
  color: #fff;
}

.dashboardback {
  width: 200px;
  height: 35px;
  background-color: #7ead18 !important;
  border-radius: 20px !important;
  margin-top: 16px !important;
  color: #fff !important;
}

.hg-theme-default .hg-button{
  color: #000;
}

/* .developedby {
  position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
} */

.developedby {
  align-items: center;
  gap: 8px;
  display: flex;
  /* position: absolute; */
  bottom: 15px;
  right: 15px;
  justify-content: end;
  margin-top: 60px;
}
.developedtext{
  text-align: center;
  font-size: 14px;
}
.mrginleftinbig{
  margin-right: 100px;
}
@media (max-width: 899px) {
  .keyboardclass{
    width: 100% !important;
  }
}
.mobilebuttons{
  display: none;
}
@media (max-width: 767px) {
  .desktopscreen{
    display: none;
  }
  .mobilebuttons{
    display: block;
  }
  .mobilebuttons .classspositioncent2{
    top: 190px;
  }
  .mrginleftinbig{
    margin-right: 0px;
  }
  #output-container {
      flex-direction: column-reverse;
      align-items: center;
      /* justify-content: center; */
      min-height: 45vh;
      /* min-height: 21vh; */
  }
  .keyboardclass{
    width: 100% !important;
    display: none !important;
  }
  .hello-container {
      /* padding: 90px; */
      padding: 21px;
      height: 28vh;
  }
  .messages-container{
    /* max-height: 18vh; */
    max-height: 25vh;
    width: 95%;
  }

  .container{
    min-height:68vh !important;
  }

  .videocon{
    top: 50px;
    height: 150px !important;
    left: 38% !important;
    position: absolute;
  }
  .positionrelative{
    position: initial !important;
  
  }

  .userformHeader2{
    display: none;
  }
  .whitecontainer{
    border-radius: 60px !important;
    margin-top: 10px !important;
    padding: 20px !important;
    padding-bottom: 0px !important;
    position: relative;
  }
  .fromfillinginput{
    margin-top: 0px;
  }
  .userformHeader {
    font-size: 20px !important;
    margin-bottom: 190px !important;
  }
  .logopss{
    width: 170px;
  }
  .Backgroundcolor2{
    bottom: -38px !important;
    left: 41% !important;
  }
  .datepickerlocal {
    min-width: inherit !important;
  }
  .mobilebuttons .Backgroundcolor2{
    width: 40px;
    height: 40px;
  }
  .mobilebuttons .Backgroundcolor2 img{
    width: 20px;
    height: 20px;
    margin: 10px !important;
  }
 
  .developedby{
    position: relative;
    margin-top: 30px;
    top: 0;
    right: 0;
    justify-content: right;
  }
}
@media (max-width: 400px) {
  
  .exportButon{
    margin-top: 15px;
  }
}

.dnonevisibilitynone{
  visibility: hidden;
  position: absolute;
  top: 0;
}