.frame {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.frame .overlap-wrapper {
  background-color: #ffffff;
  height: 890px;
  width: 706px;
}

.frame .overlap {
  height: 822px;
  left: 49px;
  position: relative;
  top: 34px;
  width: 621px;
}

.frame .rectangle {
  background-color: #ffffff;
  border-radius: 33px;
  box-shadow: 0px 4px 15px 5px #0000001a;
  height: 822px;
  left: 0;
  position: absolute;
  top: 0;
  width: 608px;
}

.frame .div {
  background-color: #7ead18;
  border-radius: 33px 33px 0px 0px;
  height: 73px;
  left: 0;
  position: absolute;
  top: 0;
  width: 608px;
}

.frame .group {
  height: 45px;
  left: 23px;
  position: absolute;
  top: 17px;
  width: 567px;
}

.frame .text-wrapper {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8px;
  font-weight: 300;
  left: 52px;
  letter-spacing: 0;
  line-height: 10.8px;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.frame .text-wrapper-2 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  text-align: center;
  top: 18px;
  white-space: nowrap;
}

.frame .fi {
  height: 20px;
  left: 543px;
  position: absolute;
  top: 10px;
  width: 20px;
}

.frame .group-2 {
  background-color: #ffffff;
  
  background-size: 100% 100%;
  border-radius: 22.5px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.frame .line {
  height: 1px;
  left: 510px;
  object-fit: cover;
  position: absolute;
  top: 28px;
  width: 19px;
}

.frame .women-chat {
  height: 436px;
  left: 293px;
  object-fit: cover;
  position: absolute;
  top: 272px;
  width: 328px;
}

.frame .img {
  height: 619px;
  left: 597px;
  position: absolute;
  top: 80px;
  width: 5px;
}

.frame .line-2 {
  height: 465px;
  left: 598px;
  position: absolute;
  top: 234px;
  width: 5px;
}

.frame .group-3 {
  height: 20px;
  left: 498px;
  position: absolute;
  top: 26px;
  width: 20px;
}

.frame .overlap-group-wrapper {
  height: 42px;
  left: 0;
  position: absolute;
  top: 754px;
  width: 608px;
}

.frame .overlap-group {
  height: 42px;
  position: relative;
}

.frame .type-text {
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 608px;
}

.frame .overlap-group-2 {
  height: 42px;
  position: relative;
  width: 610px;
}

.frame .group-4 {
  height: 41px;
  left: 0;
  position: absolute;
  top: 0;
  width: 610px;
}

.frame .line-3 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 608px;
}

.frame .text-wrapper-3 {
  color: var(--intercom-reply-text-placeholder);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  text-align: center;
  top: 17px;
  white-space: nowrap;
}

.frame .fi-2 {
  height: 20px;
  left: 515px;
  position: absolute;
  top: 21px;
  width: 20px;
}

.frame .fi-3 {
  height: 20px;
  left: 25px;
  position: absolute;
  top: 18px;
  width: 20px;
}

.frame .fi-4 {
  height: 22px;
  left: 548px;
  position: absolute;
  top: 20px;
  width: 22px;
}

.frame .fi-5 {
  height: 20px;
  left: 485px;
  position: absolute;
  top: 22px;
  width: 20px;
}

.frame .chat {
  height: 650px;
  left: 24px;
  overflow-y: scroll;
  position: absolute;
  top: 91px;
  width: 583px;
}

.frame .operator {
  height: 53px;
  left: 35px;
  position: absolute;
  top: 58px;
  width: 234px;
}

.frame .message-wrapper {
  align-items: flex-end;
  display: flex;
  gap: 10px;
  height: 53px;
  position: relative;
  width: 234px;
}

.frame .message {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  justify-content: flex-end;
  position: relative;
}

.frame .div-wrapper {
  align-items: flex-start;
  background-color: var(--intercom-operator-message-bg);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  padding: 16px 18px;
  position: relative;
}

.frame .p {
  align-self: stretch;
  color: var(--intercom-operator-message-text);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-top: -1px;
  position: relative;
}

.frame .frame-wrapper {
  height: 53px;
  left: 35px;
  position: absolute;
  top: 138px;
  width: 234px;
}

.frame .message-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  justify-content: flex-end;
  margin-right: -52px;
  position: relative;
}

.frame .operator-2 {
  height: 53px;
  left: 35px;
  position: absolute;
  top: 296px;
  width: 234px;
}

.frame .operator-3 {
  height: 53px;
  left: 35px;
  position: absolute;
  top: 486px;
  width: 234px;
}

.frame .operator-4 {
  height: 53px;
  left: 35px;
  position: absolute;
  top: 575px;
  width: 234px;
}

.frame .message-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: flex-end;
  margin-right: -42px;
  margin-top: -13px;
  position: relative;
  width: 276px;
}

.frame .click-on-the-leave-wrapper {
  align-items: flex-start;
  background-color: var(--intercom-operator-message-bg);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  padding: 16px 18px;
  position: relative;
  width: 210px;
}

.frame .click-on-the-leave {
  color: var(--intercom-operator-message-text);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 175px;
}

.frame .div-wrapper-2 {
  align-items: center;
  background-color: var(--intercom-user-bg);
  border-radius: 6px;
  display: inline-flex;
  gap: 10px;
  justify-content: flex-end;
  left: 54px;
  max-width: 300px;
  overflow: hidden;
  padding: 16px 19px;
  position: absolute;
  top: 220px;
}

.frame .text-wrapper-4 {
  color: var(--intercom-user-message-text);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-top: -1px;
  position: relative;
}

.frame .fi-wrapper {
  background-color: #3047ec;
  border-radius: 14.5px;
  height: 29px;
  left: 287px;
  position: absolute;
  top: 230px;
  width: 29px;
}

.frame .fi-6 {
  height: 16px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 16px;
}

.frame .group-5 {
  background-color: #d9d9d9;
  
  background-size: 100% 100%;
  border-radius: 14.5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 29px;
}

.frame .group-6 {
  background-color: #d9d9d9;
  
  background-size: 100% 100%;
  border-radius: 14.5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 154px;
  width: 29px;
}

.frame .group-7 {
  background-color: #d9d9d9;
  
  background-size: 100% 100%;
  border-radius: 14.5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 312px;
  width: 29px;
}

.frame .group-8 {
  background-color: #d9d9d9;
  
  background-size: 100% 100%;
  border-radius: 14.5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 502px;
  width: 29px;
}

.frame .group-9 {
  background-color: #d9d9d9;
  
  background-size: 100% 100%;
  border-radius: 14.5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 580px;
  width: 29px;
}

.frame .frame-wrapper-2 {
  align-items: flex-end;
  display: flex;
  gap: 10px;
  height: 35px;
  left: 35px;
  position: absolute;
  top: 375px;
  width: 109px;
}

.frame .div-wrapper-3 {
  align-items: center;
  background-color: #3047ec;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  height: 35px;
  justify-content: center;
  max-width: 300px;
  padding: 16px 18px;
  position: relative;
}

.frame .text-wrapper-5 {
  align-self: stretch;
  color: var(--white);
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: -4.5px;
  margin-top: -6.5px;
  position: relative;
}

.frame .frame-wrapper-3 {
  align-items: flex-end;
  display: flex;
  gap: 10px;
  height: 35px;
  left: 35px;
  position: absolute;
  top: 418px;
  width: 109px;
}

.frame .div-wrapper-4 {
  align-items: center;
  background-color: #3047ec;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  height: 35px;
  justify-content: center;
  margin-right: -14px;
  max-width: 300px;
  padding: 16px 18px;
  position: relative;
}

.frame .frame-wrapper-4 {
  align-items: flex-end;
  display: flex;
  gap: 10px;
  height: 35px;
  left: 146px;
  position: absolute;
  top: 375px;
  width: 109px;
}

.frame .text-wrapper-6 {
  color: var(--intercom-stamp-text);
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 208px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 272px;
  white-space: nowrap;
}

.frame .text-wrapper-7 {
  color: #404040;
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 121px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 640px;
  white-space: nowrap;
}

.frame .text-wrapper-8 {
  color: var(--intercom-stamp-text);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 211px;
  letter-spacing: 0;
  line-height: 14.4px;
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.frame .fi-7 {
  height: 20px;
  left: 203px;
  position: absolute;
  top: 636px;
  width: 20px;
}

.frame .fi-8 {
  height: 20px;
  left: 226px;
  position: absolute;
  top: 640px;
  width: 20px;
}




/* dharmesh css start */




/* css for aligning api video start */



.overlap-wrapper .overlap .womanChatClass{
  background-color: #3047ec;
}




.chat-and-video-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.chat-section {
  flex: 1;
  overflow-y: auto;
  max-height: 80vh;
}

.video-section video{
  margin-top: 35rem;
  width: 90%;
}
.video-section {
  flex: 1;
}


/* css for aligning api video end */












/* dharmesh css end */